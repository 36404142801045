module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"generateDefaultLanguagePage":true,"languages":["en","ae","cz","de","fr","hu","il","it","pl","ru","tr","ua"],"defaultLanguage":"en","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","ae","cz","de","fr","hu","il","it","pl","ru","tr","ua"],"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KSXQFKBB","gtmAuth":"sYQv4AN5BSgdmeMVisl8UA","gtmPreview":"env-2","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
